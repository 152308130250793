<template>
  <main>
    <h1>About</h1>
    <img src="@/assets/2022_Picture.jpg" alt="">
    <p>
      Hi there! My name is Eli and I'm a local high school student. I'm involved in backstage at school, study digital solutions, and help out 
      with tech at local organisations. Hire a Grandkid is a business I started with the aim of helping members of the 4074 community with 
      both setting up new devices and teaching them how to use their existing ones. While I'm not a professional, I do have a good 
      generic understanding, and I'm more than happy to help, charging only a fraction of what a professional would.
    </p>
  
  </main>
</template>

<style scoped>
  img {
    border-radius: 50%;
    width: 35%;
    max-width: 250px;
    object-fit: cover;
    float: right;
    border: 8px solid var(--main-text-color);
    box-shadow: 0px 0px 10px black;
  }
</style>